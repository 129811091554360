<template>
  <div>
    <v-card-title class="font-nunito font-900 logo-text text-capitalize no-wrap">
      <router-link :to="{ name: 'dashboard' }">
        <v-img
          :src="$vuetify.theme.dark ? '/img/logo-ranes-darkmode.png' : '/img/logo-ranes-lightmode.png'"
          max-width="113"
          class="mt-3 mb-2 mx-2"
        ></v-img>
      </router-link>
      <v-spacer />
      <v-btn v-if="showBack" class="mr-2" small color="grey darken-2" @click="$router.go(-1)"
        ><v-icon class="mr-1">mdi-chevron-left</v-icon> {{ $t("t_back") }}</v-btn
      >
      <v-btn v-if="showHome" small color="grey darken-2" :to="{ name: 'dashboard' }"
        ><v-icon class="mr-1">mdi-home</v-icon></v-btn
      >
    </v-card-title>
    <div style="border-bottom: 5px solid #ff302f"></div>
  </div>
</template>
<script>
export default {
  name: "AppPublicHeader",
  props: {
    showHome: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
